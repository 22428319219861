
import btnsound from "../../asset/sound/btnclick.mp3";
import chipsound from "../../asset/sound/chipClick.mp3";
import countDownSound from "../../asset/sound/countdown.mp3";
import spinerSpinSound from "../../asset/sound/spiner.mp3";
import spinerEnd from "../../asset/sound/spinEnd.mp3";
import spinerWin from "../../asset/sound/winingset.mp3";
import takesound from "../../asset/sound/takeBtn.mp3";

export const BtnClickSound=()=>{
    var sound = new Audio(btnsound)
    sound.play();

}


export const ChipClickSound=()=>{
    var sound = new Audio(chipsound)
    sound.play();

}


export const CountDownSound=()=>{
    var sound = new Audio(countDownSound)
    sound.play();
}


export const SpinnerSpinSound=()=>{
    var sound = new Audio(spinerSpinSound)
    sound.play();
}


export const SpinnerEndSound=()=>{
    var sound = new Audio(spinerEnd)
    sound.play();
}

export const SpinnerWinSound=()=>{
    var sound = new Audio(spinerWin)
    sound.play();
}


export const TakeSound=()=>{
    var sound = new Audio(takesound)
    sound.play();
}
