import React, { useState } from "react";
import { BtnClickSound } from "../element/audiofile";


const AppUpdateModel = (props) => {

    return (
        <>
        <div id="popupWin2" class="popupWin block">
        
            <div className="pop_text poptext2">
                
                <div className="fd m_t_10 clr_b9 text">
                App Update is Available Download Latest version
                </div>
                <div class="fd">
                    <button className="forcelogin_bt_1 m_t_20 okbtn" onClick={()=>{
                        BtnClickSound();
                        props.updateApp();
                        }}>
                        Ok
                    </button>
                   
                </div>
            </div>
            </div>
        </>
    )

}


export default AppUpdateModel;