import React, { useEffect, useState } from "react";
import logo from "../../asset/funTarget/btnB.png";
import logo2 from "../../asset/funTarget/btnA.png";
import checkboxbackground from "../../asset/checkboxbackground.png";
import check from "../../asset/checkbox Tick.png"
import close from "../../asset/close.png";
import { BtnClickSound } from "../element/audiofile";
import { AppVersion, mysocket } from "../../config/config";

import enterBtnA from "../../asset/funTarget/enterLabelA.png";
import enterBtnB from "../../asset/funTarget/enterLabelB.png";


//import { useNavigate } from "react-router-dom";



const LoginMain = (props) => {

    // const routerHistory = useNavigate();

    useEffect(()=>{

        localStorage.removeItem("username");
        localStorage.removeItem("id");

        
        setUsernmae(localStorage.getItem("_username"));
       setPassword(localStorage.getItem("_password"));
        return(()=>{});
    },[]);


 


    const [flag, setflag] = useState(true)

    const [username, setUsernmae] = useState();
    const [password, setPassword] = useState();
    const [savePass, setSavePass] = useState(false);
    const [showPop, setShowPop] = useState(false);

    const [msg,setMsg]= useState("Please Now Login");



    const [closeClick, setClockClick] = useState(false);
    const [enterClick, setEnterClick] = useState(false);
  




    let checkedfun = () => {
        if (flag === true) {
            document.getElementsByClassName("chBox")[0].style.display = "block";
            // document.getElementsByClassName("chBox1")[0].style.display = "none";
            setflag(false);
            setSavePass(false);
        } else {
            document.getElementsByClassName("chBox")[0].style.display = "none";
            document.getElementsByClassName("chBox1")[0].style.display = "block";
            setflag(true);
            setSavePass(true);
        }
    
        // Log the updated value of flag after state update
        console.log(flag);
    }



     
    const handleLoginResponse = (e) => {
        var data = e?.respo;
    
        if (data && data.str === "ERR") {
          

            props.setModelText("Username Password is Wrong");
            props.seterrorpopup(true);
    
            // Remove the event listener after the first occurrence
            mysocket.off("ws_login_resp", handleLoginResponse);
        }


        if (data && data.str === "OK") {



        
            console.log(data["regID"]);

           

            localStorage.setItem("id",data["regID"]);
            localStorage.setItem("username",username);

          

            if(!flag===true)
            {
 
                localStorage.setItem("_username",username);
                localStorage.setItem("_password",password);
            }
            if(data["F"]==='1')
            {
                props.setAlreadyLoginPopup(true);
            }
            else
            {
                props.screenRoute("/lobby");
            }
           //   
            // Remove the event listener after the first occurrence
            mysocket.off("ws_login_resp", handleLoginResponse);
        }

    };

    const loginUser = () => {

        BtnClickSound();

        mysocket.on("connect", (e) => {
            console.log("Connected to Server");

            setMsg("Please Login Now");
        });

        mysocket.on("connect_error", (error) => {

            alert("Error to connect with socket: "+ error.message);
            setMsg("Error to connect with socket: "+ error.messag);
            console.error("Socket connection failed:", error);
            // Handle connection failure here
        });

      //  routerHistory("/lobby");

    //   props.setActivePage("/lobby");

    if(!username || !password)
    {
        props.setModelText("Enter Username and Password");
        setMsg("Enter Username and Password")
        props.seterrorpopup(true);
        return;
    }
    mysocket.emit("ws_login",{"username":username,"password":password});
    // Attach the event handler function to the "ws_login_resp" event
    mysocket.on("ws_login_resp", handleLoginResponse);
    }

    const closeHandle=()=>{

        if (window.cordova && window.cordova.platformId === 'android') {
            navigator.app.exitApp();
          }

         else  if (window.process && window.process.type === 'renderer') {
            window.close();
          }
          else 
          {
            window.close();
          }
       

    }

    return (
        <>
            <div className="fgaLoginMain">

                    <p className="topStaus">{msg}</p>

                <div className="loginMain">
                    <div className="loginDiv">
                        <div class="fd inp_elm logDf">
                            <div class="fd df">
                                <span class="funFx">GK</span>
                                <input minlength="9" maxlength="9" pattern="^[0-9]*$"  name="username" placeholder="" id="fgaUserName" defaultValue={localStorage.getItem("_username")}  onChange={(e) => { setUsernmae(e.target.value) }} autocomplete="off" style={{ fontSize: "50px" }}></input>
                            </div>

                            <div class="fd df m_t_15">
                                <input type="password" placeholder="" name="password" id="fgaPassword" minlength="6" maxlength="6" autocomplete="off" defaultValue={localStorage.getItem("_password")} onChange={(e) => { setPassword(e.target.value) }} inputMode="numeric" style={{ fontSize: "50px" }}></input>
                            </div>
                           
                            {/* <div class="fd logBtns">
                                <div class="row">
                                    <div class="col-5">
                                        <button id="submitBtn" type="submit" class="btn_1 fd logSubmt" value="Login" onClick={() =>{ loginUser()}}>Login
                                        </button>
                                    </div>
                                   
                                </div>
                            </div> */}
    
                        </div>
                    </div>
                    <div className="LogoLogBox" onClick={()=>{ BtnClickSound(); setClockClick(!closeClick); closeHandle()}}>
                        {
                            closeClick?
                            <img src={logo2} />: <img src={logo} />}
                    </div>
                   
                </div>
                <div className="enterBtn" onClick={()=>{BtnClickSound(); setEnterClick(!enterClick); loginUser()}}>
                {
                            enterClick?
                            <img src={enterBtnB} />: <img src={enterBtnA} />}
                </div>

                
               
            </div>
        </>
    )
}
export default LoginMain;