import React, { useEffect, useState } from "react";
import winlayer from "../../../../asset/winLayer.png"
import winimg0 from "../../../../asset/winimages/0win.png"
import winimg1 from "../../../../asset/winimages/1.png"
import winimg2 from "../../../../asset/winimages/2.png"
import winimg3 from "../../../../asset/winimages/3.png"
import winimg4 from "../../../../asset/winimages/4.png"
import winimg5 from "../../../../asset/winimages/5.png"
import winimg6 from "../../../../asset/winimages/6.png"
import winimg7 from "../../../../asset/winimages/7.png"
import winimg8 from "../../../../asset/winimages/8.png"
import winimg9 from "../../../../asset/winimages/9.png"


const Winpopup = (props) => {

    
   

    return(
        <>
       <div className="winlayer">
        {/* <img className="winlayercover" src={winlayer}/> */}

        

        {props.img==='0'?<img className="winlayercover" src={winimg0}/>:""}
        {props.img==='1'?<img className="winlayercover" src={winimg1}/>:""}
        {props.img==='2'?<img className="winlayercover" src={winimg2}/>:""}
        {props.img==='3'?<img className="winlayercover" src={winimg3}/>:""}
        {props.img==='4'?<img className="winlayercover" src={winimg4}/>:""}
        {props.img==='5'?<img className="winlayercover" src={winimg5}/>:""}
        {props.img==='6'?<img className="winlayercover" src={winimg6}/>:""}
        {props.img==='7'?<img className="winlayercover" src={winimg7}/>:""}
        {props.img==='8'?<img className="winlayercover" src={winimg8}/>:""}
        {props.img==='9'?<img className="winlayercover" src={winimg9}/>:""}
        
       </div>
        </>
    )
}
export default Winpopup;
