import React, { useEffect, useState } from "react";
import { mysocket } from "../../../config/config";


const Transfer =(props)=>
{

    const [arrayList,setArrayList] = useState([]);
    

    useEffect(()=>{

        mysocket.emit("ws_myAccountReciveList",localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo",(e)=>{

            console.log(e);

            setArrayList(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });

       

        return()=>{
            mysocket.off("ws_myAccountReciveList_respo");
        };
    },[])



    return(
        <div class="fd">
                                <table class="acntPopTable" cellpadding="0" cellspacing="0">
                                    <thead class="acntPopHead">
                                        <tr>
                                            <th> To</th>
                                            <th>From</th>
                                            <th>Amount</th>
                                            <th>Date</th></tr>
                                    </thead></table>
                                <table class="pointsData">
                                {arrayList.map((data1) => {
                                                return (
                                                    <tbody id="recData">
                                                    <td className="tableHeading">{data1.toAccount}</td>
                                                    <td className="tableHeading">{data1.fromAccount}</td>
                                                    <td className="tableHeading">{data1.amount}</td>
                                                    <td className="tableHeading">{data1.dateAdded}</td>
                                                   
                                                    </tbody>
                                                )
                                            })}
                                </table>
                            </div>
    );

}



export default Transfer;