import React, { useEffect, useState } from "react";
import { BtnClickSound } from "../../element/audiofile";
import { mysocket } from "../../../config/config";


const Reciviable =(props)=>
{


    const [arrayList,setArrayList] = useState([]);

    useEffect(()=>{


        mysocket.emit("ws_myAccountReciveList",localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo",(e)=>{

            console.log(e);

            setArrayList(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });

        return()=>{
            mysocket.off("ws_myAccountReciveList_respo");

        };
    },[])



    const refreshData=()=>{

        mysocket.emit("ws_myAccountReciveList",localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo",(e)=>{

            console.log(e);

            setArrayList(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });

    }


    const acceptPoint=(e)=>{

        BtnClickSound();

        mysocket.emit("ws_myAccountAcceptAmount",e.toAccount,e.amount,e.tranID);
        mysocket.on("ws_myAccountAcceptAmount_respo",(e)=>{

            console.log(e);

           if(e["str"]==="OK")
           {
            props.setModelText("Accepted Amount");
            props.seterrorpopup(true);
            refreshData();
            mysocket.emit("ws_getBalance", localStorage.getItem("username"));

            mysocket.on("ws_mybalance", (e) => {

                localStorage.setItem("balance",e);

                props.setBalance(e);

                mysocket.off("ws_mybalance");

            });

           }
           else
           {
            props.setModelText("Error to Accept");
            props.seterrorpopup(true);
           }

           mysocket.off("ws_myAccountRejectAmount_respo");

        });


    }


    const rejectPoint =(e)=>{

        BtnClickSound();

        mysocket.emit("ws_myAccountRejectAmount",e.fromAccount,e.amount,e.tranID);
        mysocket.on("ws_myAccountRejectAmount_respo",(e)=>{

            console.log(e);

           if(e["str"]==="OK")
           {
            props.setModelText("Rejected Amount");
            props.seterrorpopup(true);
            refreshData();
           }
           else
           {
            props.setModelText("Error to Reject");
            props.seterrorpopup(true);
           }

           mysocket.off("ws_myAccountRejectReject_respo");

        });

    }



    return(
        <div>
        <table class="acntPopTable" cellpadding="0" cellspacing="0">
            <thead class="acntPopHead"><tr><th>To</th><th>From</th><th>
                Amount </th>
                <th>  Date </th>
                <th>  Option </th>
            </tr></thead></table>
        <table class="pointsData">
           
                {arrayList.map((data1) => {
                                                return (
                                                    <tbody id="recData">
                                                    <td className="tableHeading">{data1.toAccount}</td>
                                                    <td className="tableHeading">{data1.fromAccount}</td>
                                                    <td className="tableHeading">{data1.amount}</td>
                                                    <td className="tableHeading">{data1.dateAdded}</td>
                                                    <td className="tableHeading"><button className="reciveTakeBtn ar_bt_1" onClick={()=>{acceptPoint(data1)}}>Receive</button><button className="reciveTakeBtn ar_bt_1" onClick={()=>{rejectPoint(data1)}}>Reject</button></td>
                                                    </tbody>
                                                )
                                            })}
        </table>
    </div>
    );

}



export default Reciviable;