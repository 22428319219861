import { useEffect, useState } from 'react';
import { mysocket } from '../../config/config';


// export const  mysocket = io('http://68.178.166.16:8989');  // FunGameAsia Main APK EXE

// export const  mysocket = io('http://68.178.166.16:9898');  // FunGameAsia NAPI


// export const  mysocket = io('http://132.148.77.106:9898');  // FunGameAsia BKK

// export const  mysocket = io('http://localhost:8989');    //dev env

export const GetSocket=(props)=>
{

    console.log("Connecting to socket");

    const[connected, setConnected] = useState(false);

    useEffect(() => {
      

        mysocket.on("connect",(e)=>{

            console.log("Connected to Server");
            props.setNointernetppopup(false);
        });


        mysocket.on('disconnect', () => {
          console.log('Socket disconnected');
          setConnected(false);
          props.setNointernetppopup(true);
      });


        if(connected)
        {
          return () => {
           
            mysocket.disconnect();
          };
        }
        else
        {
          console.log("Connection in progress...");
          setConnected(false);
          
          props.setNointernetppopup(true);
          
        }
    
       
      }, []);
}