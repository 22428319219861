import React, { useEffect, useState } from "react";
import { BtnClickSound } from "../../element/audiofile";
import { mysocket } from "../../../config/config";


const ChangePin =(props)=>
{

    const[password, setPassword] = useState("");
    const[newpass, setNewPass] = useState("");
    const[confirmPass, setConfirmPass] = useState("");

    
    const changePassword =()=>{
        BtnClickSound();
        try {   

            if(newpass !== confirmPass)
            {
                props.setModelText("Password is Mismatched");
                props.seterrorpopup(true);
                
                return;
            }

            var data = {
                "usercode":localStorage.getItem("username"),
                "newPin": newpass,
                "currentPin":password
            };

            console.log(data);

            mysocket.emit("ws_changePin",data);
            
            
            mysocket.on("ws_changePin_respo",(e)=>{

                console.log(e);

                if(e["str"]==="OK")
                {
                    props.setModelText("PIN Changed");
                    props.seterrorpopup(true);
                }
                else
                {
                    props.setModelText("Error to Change PIN");
                    props.seterrorpopup(true);
                }

                mysocket.off("ws_changePass_respo");

            });

        } catch (error) {

            console.log("Error in Point Transfer");
        }
    }





    return(
        <div>
                <div class="inputTabs">
                                <div><img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png"/>
                                </div>
                                <input type="password" name="pin" placeholder="PIN" id="opin" minlength="4" maxlength="4" pattern="[0-9]*" inputmode="numeric" autocomplete="off" onChange={(e)=>{setPassword(e.target.value)}} />
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <input type="password" name="newPin" placeholder="New PIN" id="nPin" minlength="4" maxlength="4" pattern="[0-9]*" inputmode="numeric" autocomplete="off" onChange={(e)=>{setNewPass(e.target.value)}} />
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <input type="password" name="cnfPin" placeholder="Confirm PIN" id="cPin" minlength="4" maxlength="4" pattern="[0-9]*" inputmode="numeric" autocomplete="off" onChange={(e)=>{setConfirmPass(e.target.value)}} />
                                <div><img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <button id="okBtn3" class="myaccountClose disableBtn" disabled="" onClick={()=>{changePassword()}}>Ok</button>
                            </div>
                            <div class="fd respTxt"><p id="pinres">
                            </p>
                            </div>
        </div>
    );

}



export default ChangePin;