import React, { useEffect, useState } from "react";

// import user from "../../media/user.f2b49fcc.svg"
import chip from "../../media/chip_delete.524cd33d.png";
import logo_anime from "../../asset/logo_anim.4b36cc8f.gif";
import MATKA_NON from "../../asset/MATKA_NON.c0d20112.png";
import EUROPEAN_ROULETTE from "../../asset/EUROPEAN_ROULETTE.af4b6f45.png";
import ROULETTE from "../../asset/ROULETTE.1e65e1b9.png"
import TARGET from "../../asset/TARGET.89c0c9ae.png"
import FUN_CARD from "../../asset/FUN_CARD.13fdcb5a.png"
import MATKA1 from "../../asset/MATKA1.5b76c008.png"
import EUROPEAN_ROULETTE_TIMER from "../../asset/EUROPEAN_ROULETTE_TIMER.cca57ffb.png"
import FUN_ROULETTE from "../../asset/FUN_ROULETTE.d8c5bdf3.png"
import FUN_TARGET from "../../asset/FUN_TARGET.5cf6db12.png"
import SORAT_TIMER from "../../asset/SORAT_TIMER.3e2d4480.png"
import MyAccount from "./MyAccount";
import accountIcon from "../../asset/userIcon.png";
import coinChip from "../../asset/chipStack.png";
import { BtnClickSound } from "../element/audiofile";
import { mysocket } from "../../config/config";

import pta from "../../asset/funTarget/ptA.png";
import ptb from "../../asset/funTarget/ptB.png";

import settingIcon from "../../asset/funTarget/settingIcon.png";


import logouta from "../../asset/funTarget/logoutA.png";
import logoutb from "../../asset/funTarget/logoutB.png";


import refreshA from "../../asset/funTarget/refreshA.png";
import refreshB from "../../asset/funTarget/refreshB.png";

import checkAuthImg from "../../asset/funTarget/checkAuth.png";


import transferAA from "../../asset/funTarget/transferAA.png";
import transferAB from "../../asset/funTarget/transferAB.png";

import transferBA from "../../asset/funTarget/transferBA.png";
import transferBB from "../../asset/funTarget/transferBB.png";

import bgPanel from "../../asset/funTarget/FunTargetBg.png";


const DashBoard = (props) => {


    const [balance, setBalance] = useState(0);

    const [transClick, settransClick] = useState(false);
    const [refreshClick, setrefreshClick] = useState(false);
    const [logoutClick, setlogoutClick] = useState(false);
    const [checkAuthClick, setcheckAuthClick] = useState(false);
    const [transferClick, settransferClick] = useState(false);
    const [cancelClick, setcancelClick] = useState(false);

    const [transrespo, setTransRespo] = useState("Response");


    const [showpopup, setshowpopup] = useState(false);


    const [uid, setUid] = useState("");
    const [pin, setPin] = useState("");
    const [amount, setAmount] = useState("");
    const [msg, setMssg] = useState("");


    const[mainRespo,setmainRespo] = useState("Response");


    // receve and trans

    const [arrayListrecive, setArrayListrecive] = useState([]);
    const [arrayListtrans, setArrayListtrans] = useState([]);



    useEffect(() => {


        mysocket.emit("ws_myAccountReciveList", localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo", (e) => {

            console.log(e);

            setArrayListrecive(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });

        return () => {
            mysocket.off("ws_myAccountReciveList_respo");

        };
    }, [])

    
    useEffect(()=>{

        mysocket.emit("ws_myAccountReciveList",localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo",(e)=>{

            console.log(e);

            setArrayListtrans(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });

       

        return()=>{
            mysocket.off("ws_myAccountReciveList_respo");
        };
    },[])

    const refreshData = () => {

        mysocket.emit("ws_myAccountReciveList", localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo", (e) => {

            console.log(e);

            setArrayListrecive(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });


        mysocket.emit("ws_myAccountReciveList",localStorage.getItem("username"));

        mysocket.on("ws_myAccountReciveList_respo",(e)=>{

            console.log(e);

            setArrayListtrans(e);

            mysocket.off("ws_myAccountReciveList_respo");
        });


    }

    const acceptPoint = (e) => {

        BtnClickSound();

        mysocket.emit("ws_myAccountAcceptAmount", e.toAccount, e.amount, e.tranID);
        mysocket.on("ws_myAccountAcceptAmount_respo", (e) => {

            console.log(e);

            if (e["str"] === "OK") {

                setmainRespo("Accepted Amount");
               
                refreshData();
                mysocket.emit("ws_getBalance", localStorage.getItem("username"));

                mysocket.on("ws_mybalance", (e) => {

                    localStorage.setItem("balance", e);

                    setBalance(e);

                    mysocket.off("ws_mybalance");

                });

            }
            else {
                setmainRespo("Error to Accepted Amount");
            }

            mysocket.off("ws_myAccountRejectAmount_respo");

        });


    }


    const rejectPoint = (e) => {

        BtnClickSound();

        mysocket.emit("ws_myAccountRejectAmount", e.fromAccount, e.amount, e.tranID);
        mysocket.on("ws_myAccountRejectAmount_respo", (e) => {

            console.log(e);

            if (e["str"] === "OK") {
                setmainRespo("Rejected Amount");
                refreshData();
            }
            else {
                setmainRespo("Error to reject");
            }

            mysocket.off("ws_myAccountRejectReject_respo");

        });

    }


    const pointSaveTransfer = () => {

        BtnClickSound();

        if (uid.length <= 0 || pin.length <= 0 || amount.length <= 0) {

            setTransRespo("Enter All Data");

            return false;
        }

        try {

            var data = {
                "usercode": localStorage.getItem("username"),
                "toUser": uid,
                "pin": pin,
                "amount": amount

            };

            console.log(data);

            mysocket.emit("ws_pointTransfer", data);


            mysocket.on("ws_pointTransfer_respo", (e) => {
                if (e["str"] === "OK") {
                    setTransRespo("Transfer Done");
                    // props.setModelText("Transfer Done");
                    // props.seterrorpopup(true);
                }
                else {
                    setTransRespo("Transfer Error");
                    // props.setModelText("Transfer Error");
                    // props.seterrorpopup(true);
                }

                mysocket.off("ws_pointTransfer_respo");

            });

        } catch (error) {

            console.log("Error in Point Transfer");
        }
    }


    useEffect(() => {
        mysocket.emit("ws_getBalance", localStorage.getItem("username"));
        mysocket.on("ws_mybalance", (e) => {
            console.log("Balance is:");
            console.log(e);
            localStorage.setItem("balance", e);
            setBalance(e);
        });

        return (() => {
            mysocket.off("ws_mybalance");
        });

    }, [])



    const [myaccount, setmyaccount] = useState();
    const myaccountclick = () => {
        setmyaccount(true);
    }
    const myaccountclose = () => {
        setmyaccount(false);
    }


    const funtargetTiner = () => {


        mysocket.emit("ws_checkBlockedLevels", localStorage.getItem("username"), 1);

        mysocket.on("ws_checkBlockedLevels_respo", (e) => {

            console.log(e);

            if (e["str"] === "OK") {
                props.screenRoute("/funtargettimer");
            }
            else {

                props.setModelText("Level will Be Available soon");
                props.seterrorpopup(true);

            }

        });


    }

    return (
        <>
            {myaccount && <MyAccount myaccountclose={myaccountclose} seterrorpopup={props.seterrorpopup} setModelText={props.setModelText} balance={balance} setBalance={setBalance} />}
            {!myaccount && <div class="fgaLobby p_lr_15">
                <p className="topDashId labelFont">GKProuser</p>
                <p className="topDashAmount labelFont">767.00</p>
                <br />
                <div class="radio-group">
                    <label className="labelFont radioGroupSpacer dashLabel">
                        <input type="radio" name="pointType" value="gkPoints" className="radiobtn" />
                        GK Points
                    </label>

                    <label className="labelFont radioGroupSpacer dashLabel">
                        <input type="radio" name="pointType" value="multiplayerPoints" className="radiobtn" />
                        Multiplayer Points
                    </label>
                </div>

                <div className="settingIcon">
                    <img alt="settingIcon" src={settingIcon} />
                </div>

                <div className="pointName" onClick={() => { BtnClickSound(); settransClick(!transClick); setshowpopup(true);; }}>
                    {
                        transClick ?
                            <img src={pta} /> : <img src={ptb} />}
                </div>


                <div className="gamePanel">

                    <div className="gameClip" onClick={() => {
                        BtnClickSound();
                        props.screenRoute("/funtarget");
                    }}>
                        <img src={bgPanel} />
                        <label>Fun Target</label>
                    </div>

                </div>

                <div className="bottomGameBar">
                    <div className="gameResponse">{mainRespo}</div>


                </div>


                <div className="logoutBtn" onClick={() => { BtnClickSound(); setlogoutClick(!logoutClick); props.screenRoute("/login") }}>
                    {
                        logoutClick ?
                            <img src={logoutb} /> : <img src={logouta} />}
                </div>


                <div className="refreshBtn" onClick={() => { BtnClickSound();
                    
                    refreshData();
                    setrefreshClick(!refreshClick) }}>
                    {
                        refreshClick ?
                            <img src={refreshA} /> : <img src={refreshB} />}
                </div>



                <div className="checkAuth" onClick={() => { BtnClickSound(); setcheckAuthClick(!checkAuthClick) }}>
                    {
                        logoutClick ?
                            <img src={checkAuthImg} /> : <img src={checkAuthImg} />}
                </div>


                {/* table Layout design */}


                <div className="mainReciveTable">
                    <table class="acntPopTableA" cellpadding="0" cellspacing="0">
                        <thead class="acntPopHead1"><tr><th>To</th><th>From</th><th>
                            Amount </th>
                            <th>  Date </th>
                            <th>  Option </th>
                        </tr></thead></table>
                    <table class="pointsData">

                        {arrayListrecive.map((data1) => {
                            return (
                                <tbody id="recData">
                                    <td className="tableHeading1">{data1.toAccount}</td>
                                    <td className="tableHeading1">{data1.fromAccount}</td>
                                    <td className="tableHeading1">{data1.amount}</td>
                                    <td className="tableHeading1">{data1.dateAdded}</td>
                                    <td className="tableHeading1"><button className="reciveTakeBtn1" onClick={() => { acceptPoint(data1) }}>Receive</button><button className="reciveTakeBtn1" onClick={() => { rejectPoint(data1) }}>Reject</button></td>
                                </tbody>
                            )
                        })}
                    </table>
                </div>


                <div className="mainReciveTable2">
                    <table class="acntPopTableA" cellpadding="0" cellspacing="0">
                        <thead class="acntPopHead1"><tr><th>To</th><th>From</th><th>
                            Amount </th>
                            <th>  Date </th>
                            <th>  Option </th>
                        </tr></thead></table>
                    <table class="pointsData">

                        {arrayListtrans.map((data1) => {
                            return (
                                <tbody id="recData">
                                    <td className="tableHeading1">{data1.toAccount}</td>
                                    <td className="tableHeading1">{data1.fromAccount}</td>
                                    <td className="tableHeading1">{data1.amount}</td>
                                    <td className="tableHeading1">{data1.dateAdded}</td>
                                    {/* <td className="tableHeading1"><button className="reciveTakeBtn1" onClick={() => { acceptPoint(data1) }}>Receive</button><button className="reciveTakeBtn1" onClick={() => { rejectPoint(data1) }}>Reject</button></td> */}
                                </tbody>
                            )
                        })}
                    </table>
                </div>




                {/* Table Layour design end */}


                {showpopup ? <div className="pointTransfer">
                    <div className="pointTransferpop">
                        <div className="pointTransferInputGroup">
                            <input type="text" onChange={(e) => { setUid(e.target.value) }} />
                            <br />
                            <input type="text" onChange={(e) => { setPin(e.target.value) }} />
                            <br />
                            <input type="text" onChange={(e) => { setAmount(e.target.value) }} />
                        </div>
                    </div>

                    <div>
                        <div className="transCLick" onClick={() => {
                            BtnClickSound();

                            pointSaveTransfer();

                            settransferClick(!transferClick)
                        }}>
                            {
                                transferClick ?
                                    <img src={transferAB} /> : <img src={transferAA} />}
                        </div>


                        <div className="cancelClick" onClick={() => { BtnClickSound(); setcancelClick(true); setshowpopup(false); setcancelClick(false); settransClick(false) }}>
                            {
                                cancelClick ?
                                    <img src={transferBB} /> : <img src={transferBA} />}
                        </div>

                        <div className="respoLabel"><p>{transrespo}</p></div>

                    </div>

                </div> : <div></div>}



            </div>}
        </>
    );
}
export default DashBoard;