import React, { useState } from "react";
import { BtnClickSound } from "../element/audiofile";



const Errorpopup = (props) => {

    return (
        <>
        <div id="popupWin" class="popupWin block">
        
            <div class="pop_text">
                <div class="fd font_30 clr_00">
                    Alert Window
                </div>
                <div class="fd m_t_10 clr_b9">
                    {props.modelText}
                </div>
                <div class="fd">
                    <button class="forcelogin_bt_1 m_t_20" onClick={()=>{
                        
                        BtnClickSound();
                        props.seterrorpopup(false)}}>
                        Ok
                    </button>
                </div>
            </div>
            </div>
        </>
    )

}


export default Errorpopup;