import React, { useState } from "react";


const NoInternetModel = (props) => {

    return (
        <>
        <div id="popupWin2" class="popupWin block">
        
            <div className="pop_text poptext2">
                
                <div className="fd m_t_10 clr_b9 text">
                Device is Not Connected to internet
                </div>
               
            </div>
            </div>
        </>
    )

}


export default NoInternetModel;