import React, { useState } from "react";
import { BtnClickSound } from "../element/audiofile";


const NewDeviceLoginAlert = (props) => {

    return (
        <>
        <div id="popupWin2" class="popupWin block">
        
            <div className="pop_text poptext2">
                
                <div className="fd m_t_10 clr_b9 text">
                Other Device login Detected With same Userid. Force login here press ok...
                </div>
                <div class="fd">
                    <button className="forcelogin_bt_1 m_t_20 okbtn" onClick={()=>{
                        
                        BtnClickSound();
                        props.setOtherDeviceLogin(false); props.screenRoute("/login");}}>
                        Ok
                    </button>
                    {/* <button className="forcelogin_bt_1 m_t_20 cancelbtn" onClick={()=>{props.setOtherDeviceLogin(false)}}> Cancel</button> */}
                </div>
            </div>
            </div>
        </>
    )

}


export default NewDeviceLoginAlert;