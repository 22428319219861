import React, { useEffect, useState } from "react";
import { BtnClickSound } from "../../element/audiofile";
import { mysocket } from "../../../config/config";


const PointTransfer =(props)=>
{

    const[uid, setUid] = useState("");
    const[pin, setPin] = useState("");
    const[amount, setAmount] = useState("");
    const[msg,setMssg] = useState("");
   

    
    const pointSaveTransfer =()=>{

        BtnClickSound();

        try {   

            var data = {
                "usercode":localStorage.getItem("username"),
                "toUser":uid,
                "pin":pin,
                "amount":amount

            };

            console.log(data);

            mysocket.emit("ws_pointTransfer",data);


            mysocket.on("ws_pointTransfer_respo",(e)=>{
                if(e["str"]==="OK")
                {  
                    props.setModelText("Transfer Done");
                    props.seterrorpopup(true);
                }
                else
                {
                    props.setModelText("Transfer Error");
                    props.seterrorpopup(true);
                }

                mysocket.off("ws_pointTransfer_respo");

            });

        } catch (error) {

            console.log("Error in Point Transfer");
        }
    }


    return(
        <div class="inputTabs">
        <div>
            <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
        </div>
        <div class="fd">
            <span class="funFx_1">
                FUN </span>
            <input minlength="9" maxlength="9" pattern="^[0-9]*$" inputmode="numeric" name="accountNum" placeholder="To account" id="accountNum" autocomplete="off" onChange={(e)=>{setUid(e.target.value)}} />
        </div>
        <div>
            <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
        </div>
        <input type="password" name="pin" placeholder="Pin" id="pin" minlength="4" maxlength="4" pattern="[0-9]*" inputmode="numeric" autocomplete="off" onChange={(e)=>{setPin(e.target.value)}}  />
        <div><img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
        </div>
        <input type="number" name="amount" placeholder="Amount" pattern="[0-9]*" id="amount" autocomplete="off" onChange={(e)=>{setAmount(e.target.value)}} />
        <div>
            <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
        </div>
        <button id="okBtn" onClick={()=>{pointSaveTransfer()}} class="myaccountClose disableBtn" disabled="">Ok</button>
        <div class="col_2">
        </div>
        <div class="respTxt">
            <p id="amtsndres">
                {msg}
            </p>
        </div>
    </div>
    );

}



export default PointTransfer;