import React, { useEffect, useState } from "react";
import image from "../../../../asset/fun-target-bg_1.fdd9f10c.png"
import chip from "../../../../asset/chip_delete.524cd33d.png"
import targetTimerTitle from "../../../../asset/targetTimerTitle.176964eb.png"
import jackpot1 from "../../../../asset/jack1x.png"
import jackpot2 from "../../../../asset/jack2x.png"
import jackpot3 from "../../../../asset/jack3x.png"
import jackpot4 from "../../../../asset/jack4x.png"
import jackpot5 from "../../../../asset/jack5x.png"
import jackpot6 from "../../../../asset/jack6x.png"
import jackpot7 from "../../../../asset/jack7x.png"
import jackpot8 from "../../../../asset/jack8x.png"
import jackpot9 from "../../../../asset/jack9x.png"
import fttNumBg from "../../../../asset/ftt_num_bg.77dfb17e.png"
import wheel2 from "../../../../asset/wheel_2.5fe4c94a.png"
import wheel1 from "../../../../asset/wheel_1.d408a1c9.png"
import startImage from "../../../../asset/star.png"
import coin1 from "../../../../asset/coin/1.png";
import coin5 from "../../../../asset/coin/5.png";
import coin10 from "../../../../asset/coin/10.png";
import coin50 from "../../../../asset/coin/50.png";
import coin100 from "../../../../asset/coin/100.png";
import coin500 from "../../../../asset/coin/500.png";
import coin1000 from "../../../../asset/coin/1000.png";
import coin5000 from "../../../../asset/coin/5000.png";
import closeBtn from "../../../../asset/close.png";
import accountIcon from "../../../../asset/userIcon.png";
import Winpopup from "./Winpopup";
import { GenerateNumber } from "../../../element/generateRandom";
import { BtnClickSound, ChipClickSound, CountDownSound, SpinnerEndSound, SpinnerSpinSound, SpinnerWinSound, TakeSound } from "../../../element/audiofile";
import { mysocket } from "../../../../config/config";

const FunTargetTimer = (props) => {

    let [selectedCoin, setSelectedCoin] = useState(1);
    let [layerone, setlayerone] = useState(false);
    let [count, setcount] = useState(1);
    const [socket, setSocket] = useState(null);


    let [history, setHistory] = useState([])


    let [winingNumber, setWiningNumber] = useState(0);
    let [winingStatus, setWiningStatus] = useState(0);





    let [balance, setBalance] = useState(0.0);
    let [backBalance, setBackBalance] = useState(0);

    let [maxBet, setMaxBet] = useState(50000);
    let [minBet, setMinBet] = useState(10);


    let [betNumbers, setNumbers] = useState({
        0: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0
    });

    const [wining, setWining] = useState(0);


    const [btnState, setBtnState] = useState("prev");



    const [time, setTimer] = useState(0);
    const [winner, setwinner] = useState(false);

    let [winimg, setwinimg] = useState("");

    let defaultmsg = "For Amusement Only. Your minimum Bet 10 and the maximum Bet " + maxBet.toString();

    let [msg, setpopMsg] = useState(defaultmsg);


    let [totalBet, setTotalBet] = useState(0);


    let [lockBet, setLockBet] = useState(false);


    let [userWin, setUserWin] = useState(false);
    let [userWiningAmount, setUserWiningAmount] = useState(0);
    let [userTaken, setUserTaken] = useState(false);

    var btnAnimationTimer;



    let [currentSlot, setCurrentSlot] = useState("");



    let [winingObject, setWiningObject] = useState({});


    let [gameId, setGameID] = useState("");
    let [pId, setpID] = useState("");


    var timer1;
    var timer2;
    var timer3;
    var timer4;
    var timer5;








    const setMsg = (msg) => {

        setpopMsg(msg);
        setTimeout(() => {
            setpopMsg(defaultmsg);
        }, 1000);
    }

    const TotalLoader = () => {
        console.log(betNumbers);
        // Use the state updater function provided by useState
        let prevBetNumbers= betNumbers;

            let total = 0;
            for (let key in prevBetNumbers) {
                total += prevBetNumbers[key];
            }
            console.log(total);
            if (total > 1) {
                setBtnState("betok");
            }
            else {
                setBtnState("prev");
            }
            setTotalBet(total);
            // Return the updated state
            
    }

    const checkWining = (e) => {

        if (Object.entries(e).length > 0) {



            setLockBet(true);
            SpinnerWinSound();
            console.log(e);
            setwinner(true);
            setWiningNumber(e["cellID"]);
            setUserWiningAmount(e["winAmt"]);
            setWiningObject(e);
            setBtnState("take");

        
        }
        else {
            if (totalBet > 0) {
                setLockBet(false);
  
                    var betArray = { ...betNumbers };
                    for (let key in betArray) {
                        betArray[key] = 0;
                    }
                    setNumbers(betArray);
    
                    setTotalBet(0);
    
                    setBtnState("prev");
                
                
               
              
            }
        }


    }


    useEffect(()=>{
        TotalLoader();
    },[betNumbers]);


    useEffect(() => {


        mysocket.emit("ws_getBalance", localStorage.getItem("username"));

        mysocket.on("ws_mybalance", (e) => {

            console.log("Balance check");

            console.log(e);

            setBalance(e);
            setBackBalance(e);


            mysocket.off("ws_mybalance");



        });




        mysocket.emit("ws_funTargetHistory", 0);
        mysocket.on("ws_funTargetHistory_respo", (e) => {
            setHistory(e);
            console.log(e);
            mysocket.off("ws_funTargetHistory_respo");

            var lastwinnumer = e[e.length - 1];



            var winNo = parseInt(lastwinnumer.cellID);


            // this.ftt_wheelSound.play();


            var ftt_wheelAnims = document.getElementById("ftt_wheelAnim");
            const ftt_styles = {
                transition: 'all 0s',
                transform: `rotate(${(360 - (parseInt(lastwinnumer.cellID) * 36))}deg)`,
            };
            Object.assign(ftt_wheelAnims.style, ftt_styles);

            setGameID(GenerateNumber(6));
            setpID(GenerateNumber(8));

        });



        mysocket.emit("ws_funTargetSlotWining", localStorage.getItem("username"));



        mysocket.on("ws_funTargetSlotWining_respo", (e) => {
            checkWining(e);
            mysocket.off("ws_funTargetSlotWining_respo");
        });

        return () => {

            mysocket.off("ws_mybalance");

        }

    }, []);



    useEffect(() => {
        timer5 = setInterval(() => {
            setcount(count + 1);
            count % 2 === 0 ? setlayerone(true) : setlayerone(false);
        }, 200);

        if (mysocket) {
            setSocket(mysocket);
        }


        mysocket.on("ws_serverTime", (e) => {
            const timeComponents = e.split(":");
            const seconds = 60 - parseInt(timeComponents[2]);


            if (seconds <= 10 && seconds >= 1) {
                CountDownSound();
            }

            if (seconds === 60) {
                console.log("Sining Roulate");
                beforeStartSpin();
            }

            if (seconds <= 5 || seconds >= 55) {

                if (totalBet > 0 && btnState === "betok") {
                    let Countera = document.getElementById('betBtn');

                    if (Countera !== null) {
                        Countera.classList.add('borderGlow');
                    }




                }

            }
            else {
                if (totalBet > 0 && btnState === "betok") {
                    let Counterb = document.getElementById('betBtn')
                    if (Counterb !== null) {
                        Counterb.classList.remove('borderGlow');
                    }
                }
            }

            if (seconds <= 10 && seconds > 5) {

                let Counter = document.getElementById('timer')
                Counter.classList.add('timerYelloBorder');



            }
            else {
                let Counter = document.getElementById('timer');
                if (Counter && Counter.classList.contains("timerYelloBorder")) {
                    Counter.classList.remove('timerYelloBorder')
                }
            }


            if (seconds <= 5) {
                if (seconds === 5) {
                    setMsg("Bet Is Locked");

                    if (totalBet > 0 && !lockBet) {
                        placeBet();
                    }

                }
                setLockBet(true);
            }
            else {


            }
            setTimer(seconds);
        });

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            clearInterval(timer5);
            mysocket.off("ws_serverTime");

            document.removeEventListener('keydown', handleKeyDown);
        }
    }, [selectedCoin, balance, setBalance,lockBet,setNumbers,totalBet]);


    const beforeStartSpin = () => {
        mysocket.emit("ws_funTargetWining", 0);
        console.log("Emining wining");
        mysocket.on("ws_funTargetWining_respo", (e) => {
            console.log(e);
            spiner(e["cellID"], e["X"]);

            mysocket.off("ws_funTargetWining_respo");
        });
    }


    const takeWiningPrice = () => {

        TakeSound();

        mysocket.emit("ws_funTargetTakePrice", localStorage.getItem("username"), winingObject["lotTime"]);

        mysocket.on("ws_funTargetTakePrice_respo", (e) => {
            if (e["STR"] === "OK") {
                setMsg("Amount Taken");

                setLockBet(false);
                setwinner(false);
                setWiningNumber(0);
                setUserWiningAmount(0);
                setWiningObject({});
                setBtnState("prev");
                
                var betArray = { ...betNumbers };
                for (let key in betArray) {
                    betArray[key] = 0;
                }
                setNumbers(betArray);
                setTotalBet(0);

                mysocket.emit("ws_getBalance", localStorage.getItem("username"));
                mysocket.on("ws_mybalance", (e) => {
                    console.log("Balance check");
                    console.log(e);
                    setBalance(e);
                    setBackBalance(e);
                    mysocket.off("ws_mybalance");
                });
        

            }
            else {
                setMsg("Try Later");
            }

            mysocket.off("ws_funTargetTakePrice_respo");
        });

        console.log(winingObject);

    }


    let onSpinerEnd = () => {

        setMsg("You can place Bet Now");

        SpinnerEndSound();

        setLockBet(false);

        mysocket.emit("ws_funTargetHistory", 0);
        mysocket.on("ws_funTargetHistory_respo", (e) => {
            setHistory(e);
            console.log("History Updated");
            mysocket.off("ws_funTargetHistory_respo");
        });



        console.log("Checking wining");

        mysocket.emit("ws_funTargetSlotWining", localStorage.getItem("username"));

        mysocket.on("ws_funTargetSlotWining_respo", (e) => {

            checkWining(e);

            mysocket.off("ws_funTargetSlotWining_respo");
        });


        mysocket.on("error_respo", (e) => {
            console.log(e);
            mysocket.off("error_respoo");
        });





    }



    const handleKeyDown = (event) => {
        const key = event.key;
    
        if (key >= '0' && key <= '9') {
            if (lockBet) {
                setMsg("Bet Is Locked");
                return;
            }
    
            ChipClickSound();
    
            let numKey = parseInt(key);
    
            console.log(numKey);
            console.log(selectedCoin);
    
            if (numKey === 0) {
                numKey = 9;
            } else {
                numKey--;
            }
    
            if (selectedCoin === 0) {
                setNumbers(prevNumbers => ({
                    ...prevNumbers,
                    [numKey]: 0
                }));
    
                setBalance(prevBalance => prevBalance + betNumbers[numKey]);
            } else {
                if (balance - selectedCoin <= 0) {
                    setMsg("Low Balance");
                } else if ((totalBet + selectedCoin) > maxBet) {
                    setMsg("Bet Limit is " + maxBet.toString());
                } else if (lockBet) {
                    setMsg("Bet Is Locked");
                } else {
                    setNumbers(prevNumbers => ({
                        ...prevNumbers,
                        [numKey]: prevNumbers[numKey] + selectedCoin
                    }));
    
                    setBalance(prevBalance => prevBalance - selectedCoin);
                }
            }
    
            // timer1 = setTimeout(() => {
            //     TotalLoader();
            // }, 100);
    
        } else if (key === ' ') {
            if (lockBet) {
                setMsg("Bet Is Locked");
                return;
            }
    
            BtnClickSound();
    
            if (totalBet < minBet) {
                setMsg("Min Bet Amount is: " + minBet.toString());
            } else if (totalBet > maxBet) {
                setMsg("Max Bet Amount is:" + minBet); // Should this be maxBet instead of minBet?
            } else {
                placeBet();
            }
        }
    };

    const setJackpotNumber = (bonusX) => {

        let imageMarquee = document.getElementById('imageMarquee');
        if (imageMarquee && imageMarquee.classList.contains("ftt_scrollImages")) {
            imageMarquee.classList.remove('ftt_scrollImages')
        }
        console.log("bonusX" + bonusX);
        if (imageMarquee) {
            if (bonusX === undefined || bonusX === 0 || bonusX === 1) {
                let max = 8;
                let min = 2;
                let bonusValue = Math.floor(Math.random() * (max - min)) + min;

                imageMarquee.style.transform = `translateX(-${bonusValue * 220}px)`
            } else if (bonusX === 4) {
                imageMarquee.style.transform = `translateX(-${0 * 220}px)`
            } else if (bonusX === 2) {
                imageMarquee.style.transform = `translateX(-${1 * 220}px)`
            }
        }



    }


    const spinTheWheelOnload = (s) => {
        console.log(s)

        setJackpotNumber(2);

        this.resNum = Number(s);
        const ftt_styles = {
            transition: 'all 0s',
            transform: `rotate(${(360 - (this.resNum * 36))}deg)`,
        };
        try {
            Object.assign(this.ftt_wheelAnim.style, ftt_styles);
        }
        catch (e) {
            console.log("null Object found");
        }


    };


    const spiner = (winNumber, Status) => {

        let imageMarquee = document.getElementById('imageMarquee')
        imageMarquee.classList.add('ftt_scrollImages');

        setWiningNumber(winNumber);
        setWiningStatus(Status);





        var ftt_wheelAnim = document.getElementById("ftt_wheelAnim");

        var winNo = parseInt(winNumber);


        // this.ftt_wheelSound.play();



        SpinnerSpinSound();

        const styles = {
            transition: 'all 6.2s',
            transform: `rotate(${(1440 + (360 - (winNo * 36)))}deg)`,
        };

        Object.assign(ftt_wheelAnim.style, styles);




        timer2 = setTimeout(() => {
            // this.afterRotate(s);

            const styles = {
                transition: 'all 0s',
                transform: `rotate(${(360 - (winNo * 36))}deg)`,
            };
            Object.assign(ftt_wheelAnim.style, styles);




            console.log("Spiner ended");

        }, 6500)


        timer3 = setTimeout(() => {

            onSpinerEnd();
            setJackpotNumber(parseInt(Status));
        }, 6500)



    }


    const addBetNumber = (e, index) => {
        const updatedBetNumbers = { ...betNumbers };

        ChipClickSound();

        if (e.button === 0) {

            if (selectedCoin === 0) {
                setBalance(balance + updatedBetNumbers[index])
                updatedBetNumbers[index] = 0;

                setNumbers(updatedBetNumbers);



                TotalLoader();
            }
            else {

                if (balance - selectedCoin < 0) {
                    setMsg("Low Balance");

                }
                else if (lockBet) {
                    setMsg("Bet Is Locked")
                }
                else if ((totalBet + selectedCoin) > maxBet) {
                    setMsg("Bet Limit is " + maxBet.toString());
                }
                else {
                    updatedBetNumbers[index] += selectedCoin;
                    setNumbers(updatedBetNumbers);
                    setBalance(balance - selectedCoin)
                    TotalLoader();
                }
            }



        }
        if (e.button === 2) {

            if ((updatedBetNumbers[index] -= selectedCoin) >= 0) {

                var bal = balance;

                bal += selectedCoin;


                setNumbers(updatedBetNumbers);

                setBalance(bal);

                TotalLoader();

            }
            else {
                updatedBetNumbers[index] += selectedCoin
            }
        }


    }


    const clearValue = (addInBalance = true) => {


        console.log("Chceking Step 1");

        console.log(betNumbers);

        
        if (lockBet) {
            setMsg("Bet Is Locked");
            console.log("Bet is Locked");
            return true;
        }


        var betArray = { ...betNumbers };

        console.log("Chceking Step 2");

        var total = balance;


        for (let key in betArray) {
            total += betArray[key];
            betArray[key] = 0;
        }



        setNumbers(betArray);

        if (addInBalance || totalBet < minBet) {
            setBalance(total)
        }

        TotalLoader();

    }

    const doubleValue = () => {

        BtnClickSound();

        setNumbers(prevBetNumbers => {
            let updatedNumbers = { ...prevBetNumbers };
            let newBalance = balance;

            if (lockBet) {
                setMsg("Bet Is Locked");
                return prevBetNumbers;
            }

            if (totalBet * 2 > maxBet) {
                setMsg("Bet Limit is " + maxBet.toString());
                return prevBetNumbers;
            }

            for (let key in updatedNumbers) {
                const dt = updatedNumbers[key];
                // Check if the doubled value exceeds the balance
                if ((dt * 2) > newBalance) {
                    setMsg("Low Balance");
                    return prevBetNumbers;
                }


                else {
                    newBalance -= updatedNumbers[key];
                    updatedNumbers[key] = updatedNumbers[key] * 2;
                    setBalance(newBalance);
                }
            }

            return updatedNumbers;
        });

        

    }



    const placeBet = () => {

        console.log("Total bet Status: "+ totalBet);

        BtnClickSound();

        var betNum = { ...betNumbers };

  
        for (var i = betNum.length - 2; i >= 0; i--) {
            betNum[i + 1] = betNum[i];
        }

      
        betNum[0] = betNum[9];


        for (var i = 0; i < betNum.length; i++) {
            betNum[i]++;
        }

      
        console.log(betNum);



        if (totalBet < 10) {
            setMsg("Min Bet Amount is " + minBet);
           
          

            var betArray = { ...betNumbers };

            for (let key in betArray) {
                betArray[key] = 0;
            }

            mysocket.emit("ws_getBalance", localStorage.getItem("username"));
                mysocket.on("ws_mybalance", (e) => {
                    console.log("Balance check");
                    console.log(e);
                    setBalance(e);
                    setBackBalance(e);
                    mysocket.off("ws_mybalance");
                });


            setNumbers(betArray);
            setTotalBet(0);

            setBtnState("prev")
    

        }
        else if (!lockBet) {

            console.log("TotalBet is" + totalBet);

            mysocket.emit("ws_funTargetPlaceBet", localStorage.getItem("username"), betNumbers, totalBet);

            mysocket.on("ws_funTargetPlaceBet_respo", (e) => {

                console.log(e);
                // {str: 'OK', B: 1844, barcode: 2128135242}

                if (e["str"] === "OK") {
                    setMsg("Bet Placed");
                    setBalance(e["B"]);
                    setLockBet(true);

                }
                else {
                    setMsg("Error to place bet mount will refund");

                }

                mysocket.off("ws_funTargetPlaceBet_respo");

            });
        }
        else {
            setMsg("Bet Is Locked");
        }

    }


    const prevBet = () => {


        BtnClickSound();


        if (lockBet) {
            setMsg("Bet is Locked");
            return;
        }

        var prevBetArray = { ...betNumbers };

        mysocket.emit("ws_funTargetPrevBet", localStorage.getItem("username"));

        mysocket.on("ws_funTargetPrevBet_respo", (e) => {


            if (balance <= parseInt(e[0]["ticketTot"])) {
                setMsg("Low Balance");
                return;
            }

            console.log(e);
            e.forEach(function (item) {

                
                let n = parseInt(item.cellID);  

                if(n===0)
                {
                    n=9;
                }
                else
                {
                    n = n-1;
                }

            

                prevBetArray[n] = parseInt(item.cv);
            });

            setNumbers(prevBetArray);
            setBalance(balance - parseInt(e[0]["ticketTot"]));
            TotalLoader();


            mysocket.off("ws_funTargetPrevBet_respo");
        });

    }


    const BtnAnimation = (e) => {
        e.target.blur();
        e.preventDefault();
        e.target.setAttribute('style', 'transform: scale(0.8);');
        btnAnimationTimer = setTimeout(() => {
            e.target.setAttribute('style', 'transform: scale(1);');
        }, 200)
    }



    const closePage = () => {

        clearTimeout(timer1);
        clearTimeout(timer2);
        clearTimeout(timer3);
        clearTimeout(timer4);
        clearTimeout(timer5);

        clearInterval(timer5);

        props.screenRoute("/lobby");
    }

    return (

        <>
            <div class="gamePage" style={{ backgroundImage: "url(" + image + ")" }}>
                <div class="fd">
                    <div class="row">
                        <div class="target_chips">
                            <div class="fd p_5 clr_ff font_20 arial ftt_handIdCls">
                                ID: {gameId} <br />
                                P ID: {pId}</div>
                            <div class="fd chips funTarget_chips">
                                <div class="row">
                                    <div id="chipImg_1" className={selectedCoin === 1 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(1)
                                        }}>
                                            <img class="ftt_chp_img" src={coin1} /></button>
                                    </div>
                                    <div id="chipImg_10" className={selectedCoin === 5 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(5)
                                        }}>
                                            <img class="ftt_chp_img" src={coin5} />
                                        </button>
                                    </div>
                                    <div id="chipImg_25" className={selectedCoin === 10 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(10)
                                        }}>
                                            <img class="ftt_chp_img" src={coin10} /></button>
                                    </div>
                                    <div id="chipImg_50" className={selectedCoin === 50 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(50)
                                        }}>
                                            <img class="ftt_chp_img" src={coin50} /></button>
                                    </div>
                                    <div id="chipImg_100" className={selectedCoin === 100 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(100)
                                        }}>
                                            <img class="ftt_chp_img" src={coin100} /></button>
                                    </div>
                                    <div id="chipImg_500" className={selectedCoin === 500 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(500)
                                        }}>
                                            <img class="ftt_chp_img" src={coin500} /></button>
                                    </div>
                                    <div id="chipImg_1000" className={selectedCoin === 1000 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(1000)
                                        }}>
                                            <img class="ftt_chp_img" src={coin1000} /></button>
                                    </div>
                                    <div id="chipImg_5000" className={selectedCoin === 5000 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(5000)
                                        }}>
                                            <img class="ftt_chp_img" src={coin5000} /></button>
                                    </div>
                                    <div id="chipImg_0" className={selectedCoin === 0 ? "col-4 text-center Select" : "col-4 text-center UnSelect"}>
                                        <button className="chipbtn" onClick={() => {
                                            ChipClickSound();
                                            setSelectedCoin(0)
                                        }}>
                                            <img class="ftt_chp_img" src={chip} /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="target_table">
                            <div class="fd">
                                <div class="fd text_center">
                                    <img class="trgeImg_timer" src={targetTimerTitle} alt="./static/media/targetTimerTitle.176964eb.png" />
                                </div>
                                <div class="wheelBox_1_ftt m_t_10" style={{ height: "660px" }}>
                                    <img alt="starPanel" class="jack_1" src={startImage} />
                                    <div class="ftt_overallBox" id="ftt_marqueeTag">
                                        <div class="ftt_mainTrans" id="imageMarquee" style={{ transform: "translateX(-435px)" }}>
                                           
                                            <img src={jackpot4} name="xImgs" class="act" />
                                            <img src={jackpot2} name="xImgs" class="act" />
                                            <img src={jackpot1} name="xImgs" class="act" />
                                            <img src={jackpot3} name="xImgs" class="act" />
                                            <img src={jackpot5} name="xImgs" class="act" />
                                            <img src={jackpot6} name="xImgs" class="act" />
                                            <img src={jackpot7} name="xImgs" class="act" />
                                            <img src={jackpot8} name="xImgs" class="act" />
                                            <img src={jackpot9} name="xImgs" class="act" />
                                        </div>
                                    </div>
                                    <div id="ftt_wheelWraper"><div class="ftt_wheelAnimOne" onClick={() => {



                                    }}>
                                        <div className="ftt_wheelAnim" id="ftt_wheelAnim">
                                            <img src={fttNumBg} />
                                        </div>
                                    </div>
                                        <div class="ftt_wheelBorder">
                                            {layerone === true ?
                                                <img src={wheel2} /> :
                                                <img src={wheel1} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {winner && <Winpopup img={winingNumber} />}
                        </div>
                        <div class="target_btns">
                            <div class="FTT_Close"><button class="cursor_none" id="closeBtn" onClick={(e) => {
                                BtnClickSound();
                                BtnAnimation(e);
                                closePage();
                            }}>
                                <img id="close_img" src={closeBtn} alt="closeIcon" style={{ opacity: "1" }} />
                            </button>
                            </div>
                            <div class="fd">
                                <div class="ftt_userLbl">
                                    <img class="user" src={accountIcon} alt="UserIcon" style={{ margin: 10 }} />
                                    FUN {localStorage.getItem("username")}</div>
                            </div>
                            <div class="fd m_b_15"><div class="funTargetWinImg" id="funTargetWinImg" />
                            </div>
                            <div id="funTargetCover" class="funTargetCover" style={{ display: "none" }}>
                            </div>
                            <div class="timer_SecFTT" id="timer">
                                <span class="" id="timeSpan" style={{ display: "block" }}> {time} </span>
                            </div>

                            <div class="fd">
                                <div class="funTrgtTBoard"><div>{msg}</div>
                                </div>
                                <div class="fd m_t_5"><table class="ftt_HistoryTbl ftTBg Century_Gothic_B" cellpadding="0" cellspacing="0">
                                    <tbody>
                                        <tr>
                                            {history.map((data1) => {
                                                return (
                                                    <td>{data1.cellID}</td>
                                                )
                                            })}
                                            {/* <td>0</td>
                                            <td>5</td>
                                            <td>8</td>
                                            <td>7</td>
                                            <td>9</td>
                                            <td>8</td>
                                            <td>9</td>
                                            <td>5</td>
                                            <td>6</td>
                                            <td>6</td> */}
                                        </tr><tr>
                                            {history.map((data1) => {
                                                return (
                                                    <td>{data1.xval === "0" ? "1x" : data1.xval + "x"}</td>
                                                )
                                            })}
                                            {/* <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td>
                                            <td>1x</td> */}

                                        </tr>
                                    </tbody>
                                </table>
                                </div></div></div></div></div>

                <div class="bottomFixFuntarget">
                    <div class="fd fun_trg_timer_btns p_lr_10 gothic_1">
                        <div class="ftt_leftPanl">
                            <div class="fl_1">
                                <div class="funBal fd">
                                    <div>{parseFloat(parseFloat(balance).toFixed(2))}</div>
                                    <div>BALANCE</div>
                                </div>
                            </div>
                            <div class="fl_1"><div class="funBal fd">
                                <div>{totalBet}</div>
                                <div>TOTAL BET</div>
                            </div></div>
                            <div class="fl_1">
                                <div class="funBal fd">
                                    <div>{userWiningAmount}</div>
                                    <div>WINNING</div>
                                </div></div>
                        </div>
                        <div class="ftt_btnPanel">
                            <div class="fl_1">


                                {btnState === "prev" ? <button class="fd ar_bt_1 z_10" disabled="" name="PREV" onClick={(e) => {
                                    BtnAnimation(e);
                                    prevBet();




                                }}>PREV</button> : ""}

                                {btnState === "take" ? <button class="fd ar_bt_1 z_10" disabled="" onClick={(e) => {

                                    BtnAnimation(e);
                                    takeWiningPrice()
                                }} name="TAKE">TAKE</button> : ""}

                                {btnState === "betok" ? <button class="fd ar_bt_1 z_10" id="betBtn" disabled={lockBet ? true : false} name="betok" onClick={(e) => {

                                    BtnAnimation(e);

                                    placeBet();

                                }} >Bet ok</button> : ""}



                            </div>
                            <div class="fl_1">
                                <button class="fd ar_bt_1" disabled="" onClick={(e) => {
                                    BtnAnimation(e);
                                    BtnClickSound();
                                    clearValue();

                                }}> CLEAR</button>
                            </div>
                            <div class="fl_1"><button class="fd ar_bt_1" onClick={(e) => {
                                BtnAnimation(e);
                                doubleValue();

                            }} >DOUBLE  </button>
                            </div>
                        </div>
                    </div>
                    <div class="fd ">
                        <div class="FGAfunTargetTimerBetPanel">
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_1" onMouseDown={(e) => { addBetNumber(e, 0); }}>
                                <div class="buttonZeroBg"><span><div class="valueOne">1</div>
                                    <div class="valueTwo"  >{betNumbers[0] === 0 ? "" : betNumbers[0]}</div>
                                </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_2" onMouseDown={(e) => { addBetNumber(e, 1); }}>
                                <div class="buttonZeroBg"><span><div class="valueOne">2</div>
                                    <div class="valueTwo"  >{betNumbers[1] === 0 ? "" : betNumbers[1]}</div>
                                </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet">
                                <div class="buttonZero" id="FTTboxSml_3" onMouseDown={(e) => { addBetNumber(e, 2); }}>
                                    <div class="buttonZeroBg">
                                        <span>
                                            <div class="valueOne">3</div>
                                            <div class="valueTwo"  >{betNumbers[2] === 0 ? "" : betNumbers[2]}</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_4" onMouseDown={(e) => { addBetNumber(e, 3); }}>
                                <div class="buttonZeroBg">
                                    <span>
                                        <div class="valueOne">4</div>
                                        <div class="valueTwo"  >{betNumbers[3] === 0 ? "" : betNumbers[3]}</div>
                                    </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_5" onMouseDown={(e) => { addBetNumber(e, 4); }}>
                                <div class="buttonZeroBg"><span><div class="valueOne">5</div>
                                    <div class="valueTwo"  >{betNumbers[4] === 0 ? "" : betNumbers[4]}</div>
                                </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_6" onMouseDown={(e) => { addBetNumber(e, 5); }}>
                                <div class="buttonZeroBg">
                                    <span>
                                        <div class="valueOne">6</div>
                                        <div class="valueTwo"  >{betNumbers[5] === 0 ? "" : betNumbers[5]}</div>
                                    </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_7" onMouseDown={(e) => { addBetNumber(e, 6); }}>
                                <div class="buttonZeroBg">
                                    <span>
                                        <div class="valueOne">7
                                        </div>
                                        <div class="valueTwo"  >{betNumbers[6] === 0 ? "" : betNumbers[6]}</div>
                                    </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_8" onMouseDown={(e) => { addBetNumber(e, 7); }}>
                                <div class="buttonZeroBg"><span><div class="valueOne">8</div>
                                    <div class="valueTwo"  >{betNumbers[7] === 0 ? "" : betNumbers[7]}</div>
                                </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_9" onMouseDown={(e) => { addBetNumber(e, 8); }}>
                                <div class="buttonZeroBg">
                                    <span>
                                        <div class="valueOne">9</div>
                                        <div class="valueTwo"  >{betNumbers[8] === 0 ? "" : betNumbers[8]}</div>
                                    </span>
                                </div>
                            </div>
                            </div>
                            <div class="funTTBet"><div class="buttonZero" id="FTTboxSml_0" onMouseDown={(e) => { addBetNumber(e, 9); }}>
                                <div class="buttonZeroBg">
                                    <span>
                                        <div class="valueOne">0</div>
                                        <div class="valueTwo"  >{betNumbers[9] === 0 ? "" : betNumbers[9]}</div>
                                    </span>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FunTargetTimer