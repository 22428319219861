import React, { useEffect, useState } from "react";
import { BtnClickSound } from "../../element/audiofile";
import { mysocket } from "../../../config/config";



const ChangePassword =(props)=>
{





    const[password, setPassword] = useState("");
    const[newpass, setNewPass] = useState("");
    const[confirmPass, setConfirmPass] = useState("");

    
    const changePassword =()=>{
        BtnClickSound();
        try {   

            if(newpass !== confirmPass)
            {
                props.setModelText("Password is Mismatched");
                props.seterrorpopup(true);
                
                return;
            }

            var data = {
                "usercode":localStorage.getItem("username"),
                "password": newpass,
                "currentPass":password,
            };

            console.log(data);

            mysocket.emit("ws_changePass",data);
            

            mysocket.on("ws_changePass_respo",(e)=>{

                console.log("ChangePass Status: ");

                console.log(e);


                if(e["str"]==="OK")
                {
                    props.setModelText("Password Changed");
                    props.seterrorpopup(true);
                }
                else
                {
                    props.setModelText("Error to Change Password");
                    props.seterrorpopup(true);
                }

                mysocket.off("ws_changePass_respo");

            });

        } catch (error) {

            console.log("Error in Point Transfer");
        }
    }



    return(
        <div>
              <div class="inputTabs">
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <input type="password" name="pwd" placeholder="Password" id="pwd" maxlength="6" inputmode="numeric" autocomplete="off" onChange={(e)=>{setPassword(e.target.value)}} />
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <input type="password" name="newPwd" placeholder="New Password" id="nPwd" maxlength="6" inputmode="numeric" autocomplete="off" onChange={(e)=>{setNewPass(e.target.value)}} />
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <input type="password" name="cnfPwd" placeholder="Confirm Password" id="cPwd" maxlength="6" inputmode="numeric" autocomplete="off" onChange={(e)=>{setConfirmPass(e.target.value)}} />
                                <div>
                                    <img class="myAccImg" src="https://img.icons8.com/external-outline-black-m-oki-orlando/32/FFFFFF/external-equivalent-math-vol-1-outline-outline-black-m-oki-orlando.png" />
                                </div>
                                <button id="okBtn2" class="myaccountClose disableBtn" disabled="" onClick={()=>{changePassword()}} >Ok</button>
                            </div>
                            <div class="fd respTxt">
                                <p id="pwdres"></p>
                            </div>
        </div>
    );

}



export default ChangePassword;